import React from 'react'
import Admin_login from '../../components/Admin/Admin_login'
function AdminLogin() {
  return (
  <>
  <Admin_login/>
  </>
  )
}

export default AdminLogin