import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { SidebarProvider } from "./ContextApi/SidebarContext";
import AdminRoute from "./RouterFile/AdminRoute";

import ClientRouter from "./RouterFile/ClientRouter";
import AdminLogin from "./pages/admin/AdminLogin";

function App() {
  return (
    <SidebarProvider>
      <Router>
        <Routes>
        <Route path="/*" element={<ClientRouter />} />
          <Route path="/admin/*" element={<AdminRoute />} />
          <Route path="/admin/loginadmin" element={<AdminLogin />} />

        </Routes>
      </Router>
    </SidebarProvider>
  );
}

export default App;
