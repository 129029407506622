import React from 'react'
import Header from '../components/Home/Header'
import Amllogin from '../components/AML/Aml_login'
import Footer from '../components/Home/Footer'

function Aml_login() {
  return (
    <>
    <Header/>
   <Amllogin/>
   <Footer/>
    </>
  )
}

export default Aml_login