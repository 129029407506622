import Amlfirst from "../components/AML/Aml_first"
import Footer from "../components/Home/Footer"
import Header from "../components/Home/Header"




function Aml() {
  return (
    <>
   <Header isButton={2}/>
<Amlfirst/>
    <Footer/>
    </>
  )
}

export default Aml